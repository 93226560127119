import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import styles from "./contactForm.module.scss";
import footerData from "./../../mockup/footer.json";
import SelectCountry from "react-select-country-list";
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    description: "",
    fileAttachment: null,
    agreementChecked: false,
    privacyPolicyChecked: false,
    recaptchaValue: null,
  });

  const [errors, setErrors] = useState({});
  const countryOptions = SelectCountry().getData();

  const handleFormSuccess = () => {
    setIsFormSubmitted(true);
    setIsSubmitting(false);
  };

  const resetFormView = () => {
    setIsFormSubmitted(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      country: "",
      description: "",
      fileAttachment: null,
      agreementChecked: false,
      privacyPolicyChecked: false,
      recaptchaValue: null,
    });
    setErrors({});
    setIsSubmitting(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCountryChange = (selectedCountry) => {
    setFormData({ ...formData, country: selectedCountry });
  };

  const sendFormDataToBackend = async (data) => {
    try {
      const response = await fetch("https://api.xybos.tech/api/contact-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          phone: data.phone,
          country: data.country.toLowerCase(),
          message: data.description,
          "g-recaptcha-response": data.recaptchaValue,
          agree_terms: data.agreementChecked,
          subscribe_to_newsletter: false,
        }),
      });

      if (response.ok) {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "Lead",
            page_location: window.location.href,
          });
        }

        setFormData({
          name: "",
          email: "",
          phone: "",
          country: "",
          description: "",
          agreementChecked: false,
          recaptchaValue: null,
        });
        setErrors({});
        handleFormSuccess();
      } else {
        const responseData = await response.json();
        console.error("Error submitting form data:", responseData);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("There was an error sending the form data:", error);
      setIsSubmitting(false);
    }
  };

  const handleRecaptchaChange = (value) => {
    setFormData({ ...formData, recaptchaValue: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    }

    if (!formData.country) {
      newErrors.country = "Country is required";
    }

    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    if (!formData.recaptchaValue) {
      newErrors.recaptcha = "Please verify that you are not a robot.";
    }

    if (!formData.privacyPolicyChecked) {
      newErrors.privacyPolicyChecked = "Please accept the privacy policy.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      sendFormDataToBackend(formData);
    }
  };

  return (
    <div className={styles.ContactForm}>
      {!isFormSubmitted ? (
        <div className={styles.form}>
          <div className={styles.content}>
            <div className={styles.text}>
              <h6>{footerData.contactFormTitle}</h6>
              <p>{footerData.contactFormContent}</p>
            </div>
            <div className={styles.footerAnimation}></div>
          </div>

          <div className={styles.formWrap}>
            <form onSubmit={handleSubmit}>
              <div className={styles.textFieldWrap}>
                <div>
                  <TextField
                    className={styles.textField}
                    label="Name *"
                    variant="standard"
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name ? errors.name : ""}
                  />
                </div>
                <div>
                  <TextField
                    className={styles.textField}
                    label="Email *"
                    variant="standard"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email : ""}
                  />
                </div>
              </div>

              <div className={styles.textFieldWrap}>
                <div>
                  <TextField
                    className={styles.textField}
                    label="Phone"
                    variant="standard"
                    fullWidth
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone ? errors.phone : ""}
                  />
                </div>
                <div>
                  <FormControl fullWidth variant="standard">
                    <InputLabel>Country *</InputLabel>
                    <Select
                      name="country"
                      value={formData.country}
                      onChange={(e) => handleCountryChange(e.target.value)}
                      label="Country *"
                      error={!!errors.country}
                    >
                      {countryOptions.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.country && (
                      <p style={{ color: "red" }}>{errors.country}</p>
                    )}
                  </FormControl>
                </div>
              </div>

              <TextField
                className={styles.textField}
                label="Description *"
                variant="standard"
                placeholder="Describe how we can help you"
                fullWidth
                multiline
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
                error={!!errors.description}
                helperText={errors.description ? errors.description : ""}
              />
              <div className={styles.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={styles.checkbox}
                      name="agreementChecked"
                      checked={formData.agreementChecked}
                      onChange={handleChange}
                    />
                  }
                  label="Send Non-Disclosure Agreement"
                />
              </div>
              <div className={styles.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={styles.checkbox}
                      name="privacyPolicyChecked"
                      checked={formData.privacyPolicyChecked}
                      onChange={handleChange}
                    />
                  }
                  label="I agree to the Privacy Policy"
                />
                {errors.privacyPolicyChecked && (
                  <p style={{ color: "red" }}>{errors.privacyPolicyChecked}</p>
                )}
              </div>

              <ReCAPTCHA
                sitekey="6LdgF5QoAAAAAJ8e1BR2HEhk8qS3MWp7AUVgIgab"
                onChange={handleRecaptchaChange}
              />

              {errors.recaptcha && (
                <p style={{ color: "red" }}>{errors.recaptcha}</p>
              )}

              <div className={styles.submitLine}>
                <Button
                  className={styles.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Send
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className={styles.thankYouMessageWrap}>
          <div className={styles.animation}></div>

          <div className={styles.thankYouMessage}>
            <h6>WE JUST RECEIVED YOUR MESSAGE.</h6>
            <p>
              THANK YOU FOR WRITING TO US. WE ARE WORKING ON YOUR REQUEST AND
              WILL GET IN TOUCH AS SOON AS POSSIBLE.
            </p>
            <p className={styles.blueText}>HAVE A NICE DAY!</p>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              type="submit"
              onClick={resetFormView}
            >
              Back to our site
            </Button>
            <Button onClick={resetFormView} className={styles.closeBtn}>
              x
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactForm;
