import React, { useEffect, useState } from "react";
import styles from "./vacancies.module.scss";
import mainImg from "./../../assets/vacancies.png";
import { NavLink } from "react-router-dom";
import arrow from "../../assets/accordion-arrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

const VacancyCard = ({ image, title, slug }) => (
  <NavLink to={`/vacancies/${slug}`} className={styles.cardLink}>
    <div
      className={styles.cardImg}
      style={{ backgroundImage: `url(${image})` }}
    ></div>
    <div className={styles.cardBody}>
      <h5>{title}</h5>
      <span>
        <img src={arrow} alt="arrow" />{" "}
      </span>
    </div>
  </NavLink>
);

const VacanciesList = ({ vacancies }) => (
  <>
    {vacancies.map((vacancy) => (
      <VacancyCard
        key={vacancy.id}
        image={vacancy.image}
        title={vacancy.title}
        slug={vacancy.slug}
      />
    ))}
  </>
);

const Vacancies = () => {
  const [vacancies, setVacancies] = useState([]);

  useEffect(() => {
    fetch("https://api.xybos.tech/api/vacancies")
      .then((response) => response.json())
      .then((data) => setVacancies(data))
      .catch((error) => console.error("Error fetching the Vacancies:", error));
  }, []);

  if (vacancies.length === 0) {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>"Join Our Team of IT Experts | Xybos Careers"</title>
        <meta
          name="description"
          content="Explore our current job openings and take the next step in your IT career with Xybos. Join our team of experts and work on cutting-edge projects remotely."
        />
        <meta
          name="keywords"
          content="Custom software development company, Digital marketing agency, web App development company, Custom application development, offshore software development company, latvia software development companies, full service digital marketing agency"
        />
      </Helmet>
      <div className={styles.vacanciesWrapper}>
        <div className={styles.mainBlock}>
          <div className={styles.imgWrapper}>
            <img src={mainImg} alt="" />
          </div>
          <div className={styles.mainBlockFooter}>
            <div className={styles.leftBlock}> </div>
            <div className={styles.rightBlock}>
              <h1>Get your dream job</h1>
            </div>
          </div>
        </div>

        <div className={styles.vacanciesHeader}></div>

        <div className={styles.vacanciesPosts}>
          <VacanciesList vacancies={vacancies} />
        </div>
      </div>
    </>
  );
};

export default Vacancies;
