import React, { useEffect, useRef } from "react";
import styles from "./footer.module.scss";
import footerLogo from "./../../assets/footerLogo.svg";
import { Link } from "react-router-dom";
import footerData from "../../mockup/footer.json";
import fb from "./../../assets/facebook.svg";
import linkedin from "./../../assets/linkedin.svg";
import insagram from "./../../assets/instagram.svg";
import mail from "./../../assets/mail.svg";
import phone from "./../../assets/phone.svg";
import ContactForm from "../contactForm/contactForm";

const Footer = () => {
  const contactFormRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;

        if (entry.isIntersecting && entry.intersectionRatio >= 0.9) {
          if (window.dataLayer) {
            window.dataLayer.push({
              event: "formView",
              page_location: window.location.href 
            });
          }
        }
      },
      {
        threshold: 0.9,
      }
    );

    if (contactFormRef.current) {
      observer.observe(contactFormRef.current);
    }

    return () => {
      if (contactFormRef.current) {
        observer.unobserve(contactFormRef.current);
      }
    };
  }, []);
  return (
    <div className={styles.footerContainer}>
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <div className={styles.Footer}>
        <Link to="/services">
          <img
            src={footerLogo}
            alt="Logo"
            className={styles.logo}
            width={366}
            height={96}
          />
        </Link>

        <div className={styles.footerContacts}>
          <div className={styles.line}> </div>
          <div className={styles.contactInfo}>
            <ul>
              <li>
                <img src={phone} alt="" width={24} height={24} />
                <a href={`tel:${footerData.contactPhoneNumber}`}>
                  {footerData.contactPhoneNumber}
                </a>
              </li>
              <li>
                <img src={mail} alt="" width={24} height={24} />
                <a href={`mailto:${footerData.contactEmail}`}>
                  {footerData.contactEmail}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.footerLink}>
          <Link className={styles.privacyPolicyLink} to="/privacy-policy">
            {footerData.privacyPolicytitle}
          </Link>
          <Link className={styles.copyrights} to="/privacy-policy">
            {footerData.copyrights}
          </Link>

          <ul className={styles.socialLinks}>
            <li>
              <Link to={footerData.facebook}>
                <img
                  src={fb}
                  alt={footerData.facebook}
                  className={styles.social}
                  width={24}
                  height={24}
                />
              </Link>
            </li>
            <li>
              <Link to={footerData.linkedin}>
                <img
                  src={linkedin}
                  alt={footerData.linkedin}
                  className={styles.social}
                  width={24}
                  height={24}
                />
              </Link>
            </li>
            <li>
              <Link to={footerData.instagram}>
                <img
                  src={insagram}
                  alt={footerData.instagram}
                  className={styles.social}
                  width={24}
                  height={24}
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
