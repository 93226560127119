import React from "react";
import styles from "./productDevelopment.module.scss";
import HeaderProducts from "../header/headerProducts";
import productDevelopment from "../../mockup/productDevelopment.json";
import TextBox from "../textBox/textBox";
import Accordion from "../accordion/accordion";
import { Helmet } from 'react-helmet';

const ProductDevelopment = () => (
  <>
    <Helmet>
      <title>{productDevelopment.metaTitle}</title>
      <meta name="description" content={productDevelopment.metaDescription} />
      <meta name="keywords" content={productDevelopment.metaKeywords} />
    </Helmet>

    <div className={styles.headerWrapper}>
      <HeaderProducts mockUpData={productDevelopment}/>
    </div>

    <div className={styles.textWrap}>
      <TextBox text={productDevelopment.textBox} />
    </div>

    <div className={styles.accordionWrap}>
      <Accordion items={productDevelopment.items}/>
    </div>

    <div className={styles.whyUsContainer}>
      <div className={styles.whyUs}>
        <div className={styles.arrow}></div>
        <div className={styles.title}>
          <h2>{productDevelopment.whyUsTitle}</h2>
        </div>

        <div className={styles.content}>
          <h3>{productDevelopment.whyUsh3}</h3>
          <p>{productDevelopment.whyUsp}</p>
        </div>

        <div className={styles.footer}>
          <h3>{productDevelopment.whyUsfooterH3}</h3>
          <p>{productDevelopment.whyUsfooterP}</p>
        </div>
      </div>
    </div>
  </>
);

export default ProductDevelopment;
