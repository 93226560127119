import React, { useEffect, useState } from "react";
import styles from "./services.module.scss";
import quoteBg from "../../assets/landing_page/quote_bg.svg";
import quoteImg from "../../assets/landing_page/quote_img.svg";
import folder from "../../assets/folder-gradient.png";
import about from "../../assets/about-img.png";
import aboutMobile from "../../assets/about-img-mobile.png";
import servicesProductBg from "../../assets/service-products-bg.png";
import Tabs from "./tabs";
import LogoSlider from "./logoSlider";
import servicesData from "../../mockup/services.json";
import circle from "../../assets/landing_page/circle.svg";
import prev from "../../assets/arrow-prew.svg";
import next from "../../assets/arrow-next.svg";
import Tab from "./productTabs/tab";
import folderImg from "../../assets/folder-glitch.png";
import { NavLink } from "react-router-dom";

const Services = () => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const blob = document.getElementById('blob');

    const handlePointerMove = (event) => {
      const { clientX, clientY } = event;
      
      blob.animate([
        { left: blob.style.left, top: blob.style.top },
        { left: `${clientX}px`, top: `${clientY}px` }
      ], {
        duration: 3000,
        fill: 'forwards'
      });
    };

    window.addEventListener('pointermove', handlePointerMove);

    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
    };
  }, []);

  const handleTabInteraction = (index) => {
    setActiveTab(index);
  };

  const moveTab = (direction) => {
    if (direction === "next") {
      setActiveTab((prevTab) =>
        prevTab < servicesData.servicesBlockTabs.length - 1
          ? prevTab + 1
          : prevTab
      );
    } else if (direction === "prev") {
      setActiveTab((prevTab) => (prevTab > 0 ? prevTab - 1 : prevTab));
    }
  };

  return (
    <div className={styles.Services}>
  
        <div className={styles.bannerText}>
          <div className={styles.blob} id="blob"></div>
          <div className={styles.blur} id="blur"></div>
          <div className={styles.overlay}></div>

          <div className={styles.bannerContent}>
            <h1>{servicesData.mainText}</h1>
            <div className={styles.content}>

              <p className={`${styles.content__container__text} ${styles.mobileText}`}>We provide</p>

              <div className={styles.content__container}>
                <p className={styles.content__container__text}>We provide</p>
                
                <ul className={styles.content__container__list}>
                  {servicesData.servicesBlockTabs.map((service, index) => (
                    <li className={styles.content__container__list__item} key={index}>{service.title}</li>
                  ))}
                </ul>
              </div>
            </div>

            <NavLink to="/contact">{servicesData.linkText}</NavLink>
          </div>
       
      </div>

      <div className={styles.quoteAreaContainerWrap}>
        <div
          className={styles.quoteAreaContainer}
          style={{ backgroundImage: `url(${quoteBg})` }}
        >
          <div className={styles.quoteArea}>
            <img
              src={quoteImg}
              loading="lazy"
              alt="Qoute img"
              width={100}
              height={100}
            />
            <h2>{servicesData.quoteText}</h2>
          </div>
        </div>
      </div>

      <div className={styles.aboutAreaContainer}>
        <div className={styles.aboutArea}>
          <div className={styles.grid}>
            <div className={`${styles.item2by3} ${styles.aboutImage}`}>
              <img
                src={about}
                loading="lazy"
                alt="about img"
                width={820}
                height={457}
              />
            </div>
            <div className={`${styles.item2by3} ${styles.aboutImageMobile}`}>
              <img src={aboutMobile} alt="about img" width={534} height={360} />
            </div>
            <div
              className={`${styles.item1by3} ${styles.folderItemNoAnimation}`}
            >
              <img
                src={folder}
                loading="lazy"
                alt="gradient folder"
                width={388}
                height={448}
              />
            </div>
          </div>
          <div className={styles.grid}>
            <div className={`${styles.item1by3} ${styles.contentBorder}`}>
              <h6>{servicesData.ourGoalTitle}</h6>
              <p>{servicesData.ourGoalText}</p>
            </div>
            <div className={`${styles.item1by3} ${styles.folderItemMobile}`}>
              <img
                src={folderImg}
                loading="lazy"
                alt="folder"
                width={402}
                height={301}
              />
            </div>
            <div className={`${styles.item1by3} ${styles.contentBorder}`}>
              <h6>{servicesData.coreFocusTitle}</h6>
              <p>{servicesData.coreFocusText}</p>
            </div>
            <div className={`${styles.item1by3} ${styles.folderItem}`}>
              <img
                src={folderImg}
                loading="lazy"
                alt="folder"
                width={402}
                height={301}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tabsAreaContainer}>
        <div className={styles.contentArea}>
          <h2>{servicesData.whyUsTitle}</h2>
          <Tabs tabs={servicesData.tabs} />
          <LogoSlider />
          <div className={styles.footer}>
            <p>{servicesData.footer}</p>
          </div>
        </div>
      </div>

      <div
        className={styles.servicesAreaContainer}
        style={{ backgroundImage: `url(${servicesProductBg})` }}
      >
        <div className={styles.overlay}></div>

        <div className={styles.servicesArea}>
          <div className={styles.imgWrap}>
            <img loading="lazy" src={circle} alt="" width={574} height={662} />
            {servicesData.servicesBlockTabs.map((tab, index) => (
              <div
                key={index}
                className={`${styles[`tabTitle${index + 1}`]} ${
                  index === activeTab ? styles.tabTitleActive : ""
                }`}
                onMouseEnter={() => handleTabInteraction(index)}
              ></div>
            ))}
          </div>

          <div className={styles.mobileTabsContainer}>
            <div className={styles.tabButtons}>
              <h2>{servicesData.buttonTitle}</h2>

              <div>
                <button
                  className={styles.tabsButtons}
                  onClick={() => moveTab("prev")}
                  disabled={activeTab === 0}
                >
                  <img
                    loading="lazy"
                    src={prev}
                    alt="Previous tab"
                    width={14}
                    height={8}
                  />
                </button>
                <button
                  className={styles.tabsButtons}
                  onClick={() => moveTab("next")}
                  disabled={
                    activeTab === servicesData.servicesBlockTabs.length - 1
                  }
                >
                  <img loading="lazy" src={next} alt="Next tab" />
                </button>
              </div>
            </div>

            <h2 className={styles.ourServicesTitle}>
              {servicesData.ourServicesTitle}
            </h2>

            <div className={styles.tabContent}>
              {servicesData.servicesBlockTabs.map((tab, index) => (
                <div
                  key={index}
                  className={`${styles.tabPane} ${
                    index === activeTab ? styles.tabPaneActive : ""
                  }`}
                >
                  <Tab
                    title={tab.title}
                    content={tab.text}
                    link={tab.link}
                    index={index}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.textArea}>
        <h2>
          {servicesData.textAreaText}{" "}
          <span className={styles.blueText}>
            {servicesData.textAreaTextBlue}
          </span>{" "}
        </h2>
      </div>
    </div>
  );
};

export default Services;
