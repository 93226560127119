import React from "react";
import styles from "./staff.module.scss";
import HeaderProducts from "../header/headerProducts";
import staff from "../../mockup/staff.json";
import TextBox from "../textBox/textBox";
import Accordion from "../accordion/accordion";
import folder from "../../assets/folder1.svg";
import { Helmet } from "react-helmet";

const Staff = () => (
  <>
    <Helmet>
      <title>{staff.metaTitle}</title>
      <meta name="description" content={staff.metaDescription} />
      <meta name="keywords" content={staff.metaKeywords} />
    </Helmet>
    <div className={styles.headerWrapper}>
      <HeaderProducts mockUpData={staff} />
    </div>

    <div className={styles.textWrap}>
      <TextBox text={staff.textBox} />
    </div>

    <div className={styles.accordionWrap}>
      <Accordion items={staff.items} image={folder} />
    </div>
  </>
);

export default Staff;
