import React from "react";
import styles from "./graphicDesign.module.scss";
import HeaderProducts from "../header/headerProducts";
import graphicDesign from "../../mockup/graphicDesign.json";
import TextBox from "../textBox/textBox";
import arrow from "../../assets/accordion-arrow.svg";

function GraphicDesign() {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
  const setTab = (action) => {
    if (typeof action === "number") {
      setActiveTabIndex(action);
    } else if (action === "prev" && activeTabIndex > 0) {
      setActiveTabIndex((prevIndex) => prevIndex - 1);
    } else if (
      action === "next" &&
      activeTabIndex < graphicDesign.tabsItems.length - 1
    ) {
      setActiveTabIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <HeaderProducts mockUpData={graphicDesign} />
      </div>

      <div className={styles.textWrap}>
        <TextBox text={graphicDesign.textBox} />
      </div>

      <div className={styles.tabsWrap}>
        <h2>{graphicDesign.tabstitle}</h2>

        <div className={styles.contentWrap}>
          <div className={styles.list}>
            <ul>
              {graphicDesign.list.map((item, index) => (
                <li key={index}>{item.item}</li>
              ))}
            </ul>
          </div>

          <div className={styles.pattern}></div>

          <div className={styles.steps}>
            <div>
              <img src={arrow} alt="" />
            </div>
            <p>{graphicDesign.stepsWeTake}</p>
          </div>

          <div className={styles.tabMobileButtons}>
            <h6>{graphicDesign.stepsWeTake}</h6>

            <div>
              <button
                onClick={() => setTab("prev")}
                className={styles.prev}
                disabled={activeTabIndex === 0}
              >
                <img src={arrow} alt="" />
              </button>
              <button
                onClick={() => setTab("next")}
                className={styles.next}
                disabled={
                  activeTabIndex === graphicDesign.tabsItems.length - 1
                }
              >
                <img src={arrow} alt="" />
              </button>
            </div>
          </div>

          <div className={styles.tabsContainer}>
            <div className={styles.tabs}>
              {graphicDesign.tabsItems.map((tab, index) => (
                <div
                  key={index}
                  className={`${styles.tab} ${
                    activeTabIndex === index ? styles.active : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  0{index + 1}
                </div>
              ))}
            </div>
            <div className={styles.tabContent}>
              <div>
                <span>0{activeTabIndex + 1}</span>
                <h6>{graphicDesign.tabsItems[activeTabIndex].title}</h6>
              </div>
              <p>{graphicDesign.tabsItems[activeTabIndex].content}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GraphicDesign;
