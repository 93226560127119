import React from "react";
import styles from "./header.module.scss";
import productDevelopmentImg from "../../assets/services/productDevelopment.png";
import webDevelopmentServices from "../../assets/web-development-services.png";
import digitalMarketing from "../../assets/digital-marketing.png";
import graphicDesign from "../../assets/graphic-desing.png";
import constulting from "../../assets/consulting.png";
import staff from "../../assets/staff.png";
import HeaderSlider from "./headerSlider";

function HeaderProducts(props) {
  const { mockUpData } = props;
  const { headerTitle = "", headerText = "" } = mockUpData;
  const headerImg = mockUpData.headerImg;

  let selectedImage;
  switch (headerImg) {
    case "productDevelopmentImg":
      selectedImage = productDevelopmentImg;
      break;
    case "webDevelopmentServices":
      selectedImage = webDevelopmentServices;
      break;
    case "digitalMarketing":
      selectedImage = digitalMarketing;
      break;
    case "graphicDesign":
      selectedImage = graphicDesign;
      break;
    case "consulting":
      selectedImage = constulting;
      break;
    case "staff":
      selectedImage = staff;
      break;
    default:
      selectedImage = null;
  }

  return (
    <div className={styles.headerWraper}>
      <div className={styles.headerProducts}>

        <div className={styles.mobileImg}>
          <img src={selectedImage} alt="Products" />
        </div>

        <div className={styles.titleRow}>
          <h1>{headerTitle}</h1>
        </div>

        <div className={styles.contenRow}>
          <div className={styles.content}>
            <p>{headerText}</p>
          </div>
          <div
            className={styles.img}
            style={{ backgroundImage: `url(${selectedImage})` }}
          ></div>
        </div>
      </div>

      <div className={styles.headerSlider}>
        <HeaderSlider />
      </div>
    </div>
  );
}

export default HeaderProducts;
