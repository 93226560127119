import React, { useState } from "react";
import styles from "./accorion.module.scss";
import arrow from "../../assets/accordion-arrow.svg";

const Accordion = ({ items, folder }) => {
  const [activeIndices, setActiveIndices] = useState([]);

  const handleClick = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

  const isItemActive = (index) => {
    return activeIndices.includes(index);
  };

  const getNumber = (index) => {
    return index > 9 ? index : "0" + (index + 1).toString();
  };

  return (
    <div className={styles.accordion}>
      {items.map((item, index) => (
        <div key={index} className={styles.accordionItem}>
          <div
            className={`${styles.accordionTitle} ${
              isItemActive(index) ? styles.active : ""
            }`}
            onClick={() => handleClick(index)}
          >
            <span>{getNumber(index)}</span>
            <p>{item.title}</p>
            <div>
              <img src={arrow} alt="" />
            </div>
          </div>
          <div
            className={`${styles.accordionContent} ${
              isItemActive(index) ? styles.active : ""
            }`}
          >
            {folder ? (
              <>
                <img src={folder} alt="" />
                <div className={styles.contentWrap}>
                  <p>{item.content}</p>
                </div>
              </>
            ) : (
              <div className={styles.contentWrapNoFolder}>
                <p>{item.content}</p>

                <div className={styles.listWrap}>
                  <h6>Result</h6>
                  {item.ul && (
                    <ul>
                      {Object.values(item.ul).map((li, index) => (
                        <li key={index}>{li}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;