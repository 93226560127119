import React from "react";
import styles from "./digitalMarketing.module.scss";
import HeaderProducts from "../header/headerProducts";
import digitalMarketing from "../../mockup/digitalMarketing.json";
import TextBox from "../textBox/textBox";
import Accordion from "../accordion/accordion";
import folder from "../../assets/folder1.svg";
import arrow from "../../assets/accordion-arrow.svg";
import { Helmet } from "react-helmet";

function DigitalMarketing() {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
  const setTab = (action) => {
    if (typeof action === "number") {
      setActiveTabIndex(action);
    } else if (action === "prev" && activeTabIndex > 0) {
      setActiveTabIndex((prevIndex) => prevIndex - 1);
    } else if (
      action === "next" &&
      activeTabIndex < digitalMarketing.tabsItems.length - 1
    ) {
      setActiveTabIndex((prevIndex) => prevIndex + 1);
    }
  };
  return (
    <>
      <Helmet>
        <title>{digitalMarketing.metaTitle}</title>
        <meta name="description" content={digitalMarketing.metaDescription} />
        <meta name="keywords" content={digitalMarketing.metaKeywords} />
      </Helmet>
      <div className={styles.headerWrapper}>
        <HeaderProducts mockUpData={digitalMarketing} />
      </div>

      <div className={styles.textWrap}>
        <TextBox text={digitalMarketing.textBox} />
      </div>

      <div className={styles.accordionWrap}>
        <Accordion items={digitalMarketing.items} folder={folder} />
      </div>

      <div className={styles.tabsWrapper}>
        <div className={styles.tabsWrap}>
          <div className={styles.arrow}></div>
          <h2>{digitalMarketing.tabstitle}</h2>

          <div className={styles.contentWrap}>
            <div className={styles.steps}>
              <div>
                <img src={arrow} alt="" />
              </div>
              <p>{digitalMarketing.stepsWeTake}</p>
            </div>

            <div className={styles.tabMobileButtons}>
              <h6>{digitalMarketing.stepsWeTake}</h6>

              <div>
                <button
                  onClick={() => setTab("prev")}
                  className={styles.prev}
                  disabled={activeTabIndex === 0}
                >
                  <img src={arrow} alt="" />
                </button>
                <button
                  onClick={() => setTab("next")}
                  className={styles.next}
                  disabled={
                    activeTabIndex === digitalMarketing.tabsItems.length - 1
                  }
                >
                  <img src={arrow} alt="" />
                </button>
              </div>
            </div>

            <div className={styles.tabsContainer}>
              <div className={styles.tabs}>
                {digitalMarketing.tabsItems.map((tab, index) => (
                  <div
                    key={index}
                    className={`${styles.tab} ${
                      activeTabIndex === index ? styles.active : ""
                    }`}
                    onClick={() => handleTabClick(index)}
                  >
                    0{index + 1}
                  </div>
                ))}
              </div>
              <div className={styles.tabContent}>
                <div>
                  <span>0{activeTabIndex + 1}</span>
                  <h6>{digitalMarketing.tabsItems[activeTabIndex].title}</h6>
                </div>
                <p>{digitalMarketing.tabsItems[activeTabIndex].content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DigitalMarketing;
