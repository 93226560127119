import React from 'react';
import styles from './404.module.scss'
import { NavLink } from 'react-router-dom';

const NotFound = () => {

  return (
    <div className={styles.NotFound}>
      <div className={styles.NotFoundWrapper}>
          <p>PAGE NOT FOUND</p>
          <span>ERROR</span>
          <div title="404">404</div>
          <NavLink to='/'>Go Back</NavLink>
      </div>
    </div>
  );
};

export default NotFound;