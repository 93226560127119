import React, { useState, useEffect } from "react";
import styles from "./blog.module.scss";
import { NavLink } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

const BlogPostCard = ({ post }) => {
  const postUrl = `/blog/${post.slug}`;

  return (
    <div className={styles.blogPostCard}>
      <div
        className={styles.imgWrapper}
        style={{ backgroundImage: `url(${post.image})` }}
      >
        <div className={styles.overlay}></div>
      </div>

      <NavLink to={postUrl}>
        <h2>{post.title}</h2>
      </NavLink>

      <div className={styles.postFooter}>
        <p>by {post.author}</p>
        <p>{post.date}</p>
      </div>
    </div>
  );
};

const BlogPosts = ({ posts, filter }) => {
  return posts
    .filter((post) =>
      filter === "All" ? true : post.tags.some((tag) => tag.name === filter)
    )
    .map((post) => <BlogPostCard key={post.id} post={post} />);
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className={styles.pagination}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"} Previous
      </button>
      {[...Array(totalPages).keys()].map((page) => (
        <button
          key={page}
          className={currentPage === page + 1 ? styles.active : ""}
          onClick={() => onPageChange(page + 1)}
        >
          {page + 1}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next {">"}
      </button>
    </div>
  );
};

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [currentFilter, setFilter] = useState("All");
  const [currentPage, setPage] = useState(1);
  const postsPerPage = 4;

  useEffect(() => {
    fetch("https://api.xybos.tech/api/articles")
      .then((response) => response.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error fetching the posts:", error));
  }, []);

  const tags = [
    ...new Set(posts.flatMap((post) => post.tags.map((tag) => tag.name))),
  ];

  const filteredPosts = posts.filter((post) =>
    currentFilter === "All"
      ? true
      : post.tags.some((tag) => tag.name === currentFilter)
  );

  const featuredPost = filteredPosts[0];
  const totalPages = Math.ceil((filteredPosts.length - 1) / postsPerPage);

  if (posts.length === 0) {
    return (
      <div className={styles.loadingWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>"Insights and Tips on Business Development | Xybos Blog"</title>
        <meta
          name="description"
          content="Stay up-to-date with the latest insights and tips on business development from Xybos, and take your company to the next level."
        />
        <meta
          name="keywords"
          content=" Custom software development company, Digital marketing agency, web App development company, Custom application development, offshore software development company, latvia software development companies, full service digital marketing agency"
        />
      </Helmet>

      <div className={styles.blog}>
        {featuredPost && (
          <div
            className={styles.latestArticle}
            style={{ backgroundImage: `url(${featuredPost.image})` }}
          >
            <div className={styles.overlayTop}></div>
            <div className={styles.overlayGlobal}></div>

            <div className={`${styles.blogLayout} ${styles.featuredArticle}`}>
              <p className={styles.category}>
                {featuredPost.tags?.map((tag) => tag.name)}
              </p>
              <NavLink to={featuredPost.slug}>
                <h1>{featuredPost.title}</h1>
              </NavLink>
              <p className={styles.date}>{featuredPost.date}</p>
            </div>
          </div>
        )}

        <div className={styles.blogLayout}>
          <div className={styles.filters}>
            <h2>Topics</h2>

            <div className={styles.buttonsWrap}>
              {["All", ...tags].map((filter) => (
                <button key={filter} onClick={() => setFilter(filter)}>
                  {filter}
                </button>
              ))}
            </div>
          </div>

          <div className={styles.articlesWrapper}>
            <BlogPosts
              posts={posts
                .slice(1)
                .slice(
                  (currentPage - 1) * postsPerPage,
                  currentPage * postsPerPage
                )}
              filter={currentFilter}
            />
          </div>

          {filteredPosts.length > postsPerPage && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setPage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Blog;
