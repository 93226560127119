import React from "react";
import styles from "./about.module.scss";
import aboutData from "./../../mockup/about.json";
import HeaderSlider from "../header/headerSlider";
import { NavLink } from "react-router-dom";
import tabArrow from "../../assets/landing_page/tab-arrow.svg";
import Accordion from "../accordion/accordion";
import folder from "../../assets/folder3.svg";

const About = () => {

  return (
    <div className={styles.About}>
      <div className={styles.bannerImg}>
        <div className={styles.bannerAnimation}>
          <div className={styles.overlay}></div>
        </div>
        <h1>{aboutData.title}</h1>
      </div>

      <div className={styles.bannerText}>
        <h1>{aboutData.textTitle}</h1>
        <div className={styles.loading}>
          <div className={styles.loadingLineWrapper}>
            <div className={styles.loadingLine}>
              <div
                className={`${styles.loadingLineInner} ${styles.loadingLineInner1}`}
              ></div>
              <div
                className={`${styles.loadingLineInner} ${styles.loadingLineInner2}`}
              ></div>
            </div>
          </div>
        </div>

        <p>
          <span>{aboutData.spanText}</span>
          {aboutData.text}
        </p>
      </div>

      <div className={styles.headerSlider}>
        <HeaderSlider />
      </div>

      <div className={styles.textContentWrap}>
        <div>
          <div className={styles.text}>
            <div className={styles.imgWrap}></div>
            <div className={styles.innerText}>
              <p>{aboutData.textContent}</p>
            </div>
          </div>

          <div className={styles.footer}>
            <p>{aboutData.footerText}</p>
            <NavLink to="/contact">
              <img src={tabArrow} alt="Arrow" />
            </NavLink>
          </div>
        </div>
      </div>

      <div className={styles.accordionWrap}>
        <h1>{aboutData.accordionTitle}</h1>
        <Accordion items={aboutData.items} folder={folder} />
      </div>
    </div>
  );
};

export default About;
