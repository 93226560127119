import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from "./logoSlider.module.scss";
import slides from './sliderData';

const LogoSlider = () => { 
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true, 
    speed: 5000, 
    cssEase: 'linear',
    autoplaySpeed: 0,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 992, 
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className={styles.LogoSlider}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
            <div className={styles.logo} key={index}>
                <img src={slide} alt={`Logo ${index + 1}`} width={150} height={70}/>
            </div>
            ))}
      </Slider>
    </div>
  );
}

export default LogoSlider;
