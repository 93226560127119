import { useEffect,  useContext } from "react";
import { useLocation } from "react-router-dom";
import { PageViewContext } from "./App"; // Import the context from App

export const PageViewTracker = () => {
  const location = useLocation();
  const { setPageViews } = useContext(PageViewContext);
  
  useEffect(() => {
    setPageViews((prevViews) => prevViews + 1);
  }, [location, setPageViews]);

  return null; // This component doesn't render anything
};
