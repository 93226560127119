import React from "react";
import styles from "./itConsulting.module.scss";
import HeaderProducts from "../header/headerProducts";
import itConsulting from "../../mockup/itConsulting.json";
import TextBox from "../textBox/textBox";
import Accordion from "../accordion/accordion";
import folder from "../../assets/folder1.svg";
import { Helmet } from "react-helmet";

const ItConsulting = () => (
  <>
    <Helmet>
      <title>{itConsulting.metaTitle}</title>
      <meta name="description" content={itConsulting.metaDescription} />
      <meta name="keywords" content={itConsulting.metaKeywords} />
    </Helmet>
    <div className={styles.headerWrapper}>
      <HeaderProducts mockUpData={itConsulting} />
    </div>

    <div className={styles.textWrap}>
      <TextBox text={itConsulting.textBox} />
    </div>

    <div className={styles.accordionWrap}>
      <Accordion items={itConsulting.items} image={folder} />
    </div>
  </>
);

export default ItConsulting;
