import React from 'react';
import Slider from 'react-slick';
import styles from "./header.module.scss";
import quote from './../../assets/landing_page/quote_bg.svg'
import menuData from "../../mockup/header.json";

const SLIDE_COUNT = 3

const HeaderSlider = () => { 

  const settings = {
    slidesToShow:2 ,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true, 
    speed: 18000, 
    cssEase: 'linear',
    autoplaySpeed: 0,
    arrows: false,
    pauseOnHover:false,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1655,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.headerSlides}>
      <Slider {...settings} >
        {Array.from({ length: SLIDE_COUNT }, (_, index) => (
          <div key={index} className={styles.slideWrap}>
            <img src={quote} alt="STAY AHEAD IN THE COMPETITIVE DIGITAL" />
            <p>{menuData.sliderText}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default HeaderSlider;
