import React from "react";
import styles from "./webDevelopment.module.scss";
import HeaderProducts from "../header/headerProducts";
import webDevelopment from "../../mockup/webDevelopment.json";
import TextBox from "../textBox/textBox";
import Accordion from "../accordion/accordion";
import folder from "../../assets/folder2.svg";
import { Helmet } from "react-helmet";

const WebDevelopment = () => (
  <>
    <Helmet>
      <title>{webDevelopment.metaTitle}</title>
      <meta name="description" content={webDevelopment.metaDescription} />
      <meta name="keywords" content={webDevelopment.metaKeywords} />
    </Helmet>
    <div className={styles.headerWrapper}>
      <HeaderProducts mockUpData={webDevelopment} />
    </div>

    <div className={styles.textWrap}>
      <TextBox text={webDevelopment.textBox} />
    </div>

    <div className={styles.accordionWrap}>
      <Accordion items={webDevelopment.items} folder={folder} />
    </div>
  </>
);

export default WebDevelopment;
