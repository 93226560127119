import React, { useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logoSvg from "../../assets/logo.svg";
import logoDarkSvg from "../../assets/logoDark.svg";
import menuData from "../../mockup/header.json";
import styles from "./header.module.scss";

export default function Header() {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = React.useState(null);
  const isDarkTheme =
    location.pathname === "/about" ||
    location.pathname === "/privacy-policy" ||
    location.pathname.startsWith("/vacancies") ||
    (location.pathname.startsWith("/blog/") && location.pathname !== "/blog/");
    
  const [isOpen, setIsOpen] = React.useState(false);
  const navRef = useRef(null);
  const menuButtonRef = useRef(null);

  const handleMenu = (menuItem, index) => {
    if (menuItem.submenu) {
      if (openDropdown === index) {
        setOpenDropdown(null);
      } else {
        setOpenDropdown(index);
      }
    } else {
      setOpenDropdown(null);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    function handleOutsideClick(event) {
      const isButtonClicked =
        menuButtonRef.current &&
        (menuButtonRef.current === event.target ||
          menuButtonRef.current.contains(event.target));

      if (isButtonClicked) return;

      if (navRef.current && !navRef.current.contains(event.target)) {
        setOpenDropdown(null);
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1250) {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }

      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [isOpen]);

  return (
    <div className={`${styles.Header} ${isDarkTheme ? styles.lightTheme : ""}`}>
      <NavLink to="/">
        <img
          src={isDarkTheme ? logoDarkSvg : logoSvg}
          alt="Logo"
          className={styles.logo}
          width={183}
          height={50}
        />
      </NavLink>

      <nav ref={navRef} className={`${!isOpen ? styles.navHidden : ""}`}>
        <ul className={styles.menu}>
          {menuData.menu.map((menuItem, index) => (
            <li
              key={index}
              className={`${menuItem.submenu ? styles["withSubmenu"] : ""} ${
                openDropdown === index ? styles.activeDropdown : ""
              }`}
              onClick={() => handleMenu(menuItem, index)}
            >
              <NavLink to={menuItem.submenu ? "#" : menuItem.route}>
                {menuItem.label}

                {menuItem.submenu ? (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.01172 1.5L6.01172 6.5L11.0117 1.5"
                      stroke="#F8FAFC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </NavLink>

              {menuItem.submenu && openDropdown === index && (
                <ul className={styles.submenu}>
                  {menuItem.submenu.map((subitem, subindex) => (
                    <li key={subindex}>
                      <NavLink
                        to={subitem.route}
                        onClick={() => handleMenu(subitem, index)}
                        className={({ isActive }) =>
                          isActive ? styles.activeLink : ""
                        }
                      >
                        {subitem.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>

      <div
        ref={menuButtonRef}
        className={isOpen ? styles.closeIcon : styles.menuIcon}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        {isOpen ? null : <div className={styles.line}></div>}
      </div>
    </div>
  );
}
