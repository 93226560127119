import React from "react";
import { NavLink } from "react-router-dom";
import styles from ".././services.module.scss";
import tabArrow from "../../../assets/landing_page/tab-arrow.svg";
import services1 from '../../../assets/landing_page/services1.svg';
import services2 from '../../../assets/landing_page/services2.svg';
import services3 from '../../../assets/landing_page/services3.svg';
import services4 from '../../../assets/landing_page/services4.svg';
import services5 from '../../../assets/landing_page/services5.svg';
import services6 from '../../../assets/landing_page/services6.svg';

function Tab({ title, content, link, index }) {

  const servicesImages = [
    services1,
    services2,
    services3,
    services4,
    services5,
    services6
  ];

  return (
    <div className={styles.tab}>
      <div className={styles.firstRow}>
        <img src={servicesImages[index]} alt='services' width={61} height={61}/>
        <h6>{title}</h6>
      </div>
      <p>{content}</p>
      <div className={styles.buttonWrap}>
        <NavLink to={link}>
          <img src={tabArrow} alt="arrow" width={39} height={9}/>
        </NavLink>
      </div>
    </div>
  );
}

export default Tab;
