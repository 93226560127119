import React, { useEffect, useState } from 'react';
import styles from './privacyModal.module.scss';
import data from '../../mockup/privacy.json';
import { NavLink } from 'react-router-dom';

function PrivacyModal() {
  const [modalVisible, setModalVisible] = useState(null);

  useEffect(() => {
    const privacyAccepted = document.cookie.split('; ').find(row => row.startsWith('privacyAccepted='));
    if (privacyAccepted && privacyAccepted.split('=')[1] === 'true') {
      setModalVisible(false);
    } else {
      setModalVisible(true);
    }
  }, []);

  const handleConsent = (consentGiven) => {
    const consentMode = {
      'functionality_storage': consentGiven ? 'granted' : 'denied',
      'security_storage': consentGiven ? 'granted' : 'denied',
      'ad_storage': consentGiven ? 'granted' : 'denied',
      'analytics_storage': consentGiven ? 'granted' : 'denied',
      'personalization_storage': consentGiven ? 'granted' : 'denied',
    };
    const expires = new Date(Date.now() + 2592000000).toUTCString(); 
    document.cookie = `consentMode=${encodeURIComponent(JSON.stringify(consentMode))}; expires=${expires}; path=/`;
    setModalVisible(false);
    window?.gtag('consent', 'update', consentMode);

    if (consentGiven) {
      window.location.reload();
    }
  };

  const handleClose = () => {
    handleConsent(true)
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30);
    const expires = `expires=${expiryDate.toUTCString()}`;
    document.cookie = `privacyAccepted=true; ${expires}; path=/`;
    setModalVisible(false);
  };

  const handleCloseNotAccepted = () => {
    setModalVisible(false);
  };

  if (modalVisible === null) {
    return null;
  }

  return (
    <>
      {modalVisible && (
        <div className={styles.privacyModal}>
          <div className={styles.modalContent}>
            <h2>{data.title}</h2>
            <p>
              {data.text}
              <NavLink to={data.privacyLink}>
                {data.linkText}
              </NavLink>
            </p>

            <div className={styles.buttonWrap}>
              <button className={styles.closeButton} onClick={handleClose}>
                {data.buttonText}
              </button>

              <button className={styles.declineButton} onClick={handleCloseNotAccepted}>
                {data.buttonDecline}
              </button>
            </div>
 

            <button className={styles.closeButtonX} onClick={handleCloseNotAccepted}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default PrivacyModal;
