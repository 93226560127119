import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import styles from "./blog.module.scss";
import CircularProgress from '@mui/material/CircularProgress';

const SingleArticle = () => {
  let { slug } = useParams();
  const location = useLocation();
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(false);

  const trackQualityVisit = useCallback(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'qualityBlogVisit',
        url: location.pathname,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    fetch(`https://api.xybos.tech/api/articles/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        setArticle(data);
      })
      .catch((error) => setError(error));
  }, [slug]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop || document.body.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercent = (scrolled / height) * 100;

      if (scrollPercent > 70) {
        trackQualityVisit();
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [trackQualityVisit]);

  const shareToFacebook = () => {
    const url = window.location.href;
    const title = article.seo_title || '';
    const imageUrl = article.image || '';
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}&picture=${encodeURIComponent(imageUrl)}`;
  
    const width = Math.min(window.innerWidth, 600); 
    const height = Math.min(window.innerHeight, 400); 
    const left = window.innerWidth > 600 ? (window.innerWidth / 2) - (width / 2) : 0;
    const top = window.innerHeight > 400 ? (window.innerHeight / 2) - (height / 2) : 0;
  
    window.open(shareUrl, 'facebookShareWindow', `height=${height},width=${width},top=${top},left=${left}`);
  };

  if (!article) {
    return <div className={styles.loadingWrapper}><CircularProgress /></div>;
  }

  if (error) {
    return <div className={styles.loadingWrapper}>{error.message}</div>;
  }

  return (
    <div className={styles.singleArticleWrapper}>

      <div className={styles.article}>
        {article.image && <img src={article.image} alt={article.title} />}
        <div className={styles.content}>
   
          <p className={styles.category}>
            {article.tags?.map((tag) => tag.name)}
          </p>

          <h1>{article.title}</h1>

          <p className={styles.date}>{article.author} - {article.date}</p>
        </div>

        <div className={styles.overlayTop}></div>
      </div>

      <div className={styles.articleContent}>
        <button onClick={shareToFacebook} className={styles.shareButton}>
          <svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91V142.4c0-25.35 12.82-50.06 52.24-50.06h40.42V6.26S242.77 0 210.04 0C141.72 0 100.29 43.93 100.29 123.57v70.77H16.67V288h83.62v224h100.2V288z"/>
          </svg>
          Share to Facebook
        </button>
        <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
      </div>
    </div>
  );
};

export default SingleArticle;
