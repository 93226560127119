import React from "react";
import styles from "./privacyModal.module.scss";
import data from "../../mockup/privacy.json"

const PrivacyPolicy = () => (
  <div className={styles.privacyLayout}>
    <h1>{data.privacyandCookiePolicy}</h1>
    <div className={styles.PrivacyPolicytext}>
      <p>
        Your privacy is important to us. This privacy policy explains what
        personal data we collect from you and how we use it. It also explains how
        our website uses cookies. At certain times, Xybos may change or modify our
        policy at our own discretion, adding or removing information to reflect
        current practices – in other words, giving you the most up-to-date
        information. When this occurs, we will update this policy with the most
        recent revision date. We may also retain data when required to comply with
        legal obligations, enforce agreements or resolve ongoing disputes. Such
        data will be deleted when no longer required and such incidents have been
        resolved.
      </p>
      <h2>Who is responsible for processing your personal data?</h2>
      <p>
        SIA “Xybos” is the controller of your personal data. Our registered office
        is:
      </p>
      <p>Pasta stree 12-14, Limbaži, Latvia</p>
      <a href="mailtoteam@xybos.tech">team@xybos.tech</a>
      <p>
        This is registered with the Register of Entrepreneurs of the National
        Court Register maintained by the District Court in Riga.{" "}
      </p>
      <p>We are registered under entry number 40203429733.</p>
      <ul>
        <li>Our NIP (ID for taxpaying purposes) is LV40203429733</li>
      </ul>
      <h2>
        How, when, and why do we collect your Personal Data, and for how long do
        we store it?
      </h2>
      <p>
        We only store your data via request, when legal consent has been given. If
        you use our contact form, we collect your name and e-mail address. We also
        collect any additional, optional information you provide us with. We store
        your data with the message sent to us, which includes any information
        about your project or request if provided. This data is used to contact
        you in regards to your initial request. If this results in successful
        cooperation, we will continue to process your data as we provide you with
        our services. If not, your data is removed after 2 years. In limited
        circumstances, we may also use your information for other purposes, where
        permitted by law. This does not include marketing or advertising purposes.
        We process data on our own personal servers for the purposes of invoicing.
      </p>
      <h2>Protecting Your Personal Data</h2>
      <p>
        We use various technical and organizational security methods, such as
        encryption, to ensure and maintain the safe storage of your personal data.
        Your personal data is stored in secured networks and is only accessible by
        a limited number of people that have been given special, specific rights
        to access such systems.
      </p>
      <h2>Your Personal Data Rights</h2>
      <p>
        We recognize and respect your rights to your personal data. You have the
        right to request:
      </p>
      <ul>
        <li>Access to your personal data</li>
        <li>Amendments to your personal data</li>
        <li>The complete erasure of your personal data</li>
        <li>Restrictions on how we process or use your data</li>
        <li>Data portability – to obtain and reuse your own personal data</li>
      </ul>
      <p>
        You have the right to object to us processing your personal data in
        relation to your particular situation. You are also free to opt-out of
        direct marketing. In situations where we have obtained your consent to
        process your personal data, you also have the right to withdraw this
        consent at any time. This will not influence the lawful status of any
        processing that has occurred before the withdrawal occurred. To enact any
        of your personal data rights, please contact us at{" "}
        <a href="mailtoteam@xybos.tech">team@xybos.tech</a>. You also have the
        freedom to lodge a complaint with a supervisory authority. For us, as a
        Latvian company, our supervisory authority is Kristaps Lokmanis.
      </p>
      <h2>Cookies and Pixel Tags</h2>
      <p>
        We receive and record information, which may include personal data, from
        your browser when you use our website. We use a variety of methods, such
        as cookies and pixel tags to collect this information, which may include
        your:
      </p>
      <ul>
        <li>IP address</li>
        <li>
          unique cookie identifier, cookie information and information on whether
          your device has software to access certain features
        </li>
        <li>unique device identifier and device type</li>
        <li>domain, browser type and language</li>
        <li>operating system and system settings</li>
        <li>country and time zone</li>
        <li>previously visited websites</li>
        <li>
          information about your interaction with our website such as click
          behavior and indicated preferences
        </li>
        <li>access times and referring URLs</li>
      </ul>
      <p>
        Third parties may also collect information via our website through the use
        of cookies or third-party widgets and plug-ins. These particles collect
        data directly from your web browser, processing the data under their own
        privacy policies. We also use cookies and pixel tags to track how visitors
        use our website and to gain insight into their preferences. Through these,
        we collect aggregate data about site traffic and user interaction. This
        helps us identify trends and acquire statistics that can be used to
        improve the website.
      </p>
      <h2>How to manage cookies?</h2>
      <p>
        Your web browser’s settings can change your preferences regarding cookie
        storage. Most browsers also let you review and erase cookies as well.
        Please be aware, however, that our website will not operate properly
        without cookies enabled. For more information, we refer you to the help
        pages from browser manufacturers regarding managing cookies in their
        specific browsers.
      </p>
      <ul>
        <li>Google Chrome</li>
        <li>Internet Explorer</li>
        <li>Mozilla Firefox</li>
        <li>domain, browser type and language</li>
        <li>Safari – Desktop & Mobile</li>
        <li>Android Browser</li>
        <li>Opera – Desktop & Mobile</li>
      </ul>
      <p>
        For browsers not included here, please refer to the appropriate
        documentation provided by the manufacturer. If you have any issues or
        queries regarding the processing of personal data, the use of cookies, or
        our pixel tags, please contact us at{" "}
        <a href="mailtoteam@xybos.tech">team@xybos.tech</a>. For requests
        regarding your personal data and privacy complaints, we strive to resolve
        the issue in an effective and timely manner. CHANGES TO THIS PRIVACY
        POLICY Xybos has the discretion to update this privacy policy at any time.
        When we do, we will revise the updated date at the bottom of this page. We
        encourage Users to frequently check this page for any changes to stay
        informed about how we are helping to protect the personal information we
        collect. You acknowledge and agree that it is your responsibility to
        review this privacy policy periodically and become aware of modifications.
      </p>
      <h2>Acceptance terms</h2>
      <p>
        By using this Site, you signify your acceptance of this policy and terms
        of service. If you do not agree to this policy, please do not use our
        Site. Your continued use of the Site following the posting of changes to
        this policy will be deemed your acceptance of those changes.
      </p>
      <p>
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service apply</a>.
      </p>
      <h2>Contacting us</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of this
        site, or your dealings with this site, please contact us at:
      </p>
      <p>SIA “Xybos” , Pasta street 12-14, Limbaži, Latvia</p>
      <a href="mailtoteam@xybos.tech">team@xybos.tech</a>
    </div>
  </div>
);

export default PrivacyPolicy;
