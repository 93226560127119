import React from "react";
import { NavLink } from "react-router-dom";
import arrow from "../../assets/accordion-arrow.svg";
import block4Bg from "../../assets/block4-bg.svg";
import Button from "@mui/material/Button";
import data from "../../mockup/wayOfWork.json";
import globalStyles from "../../global.module.scss";
import Modal from "@mui/material/Modal";
import styles from "./wayFoWork.module.scss";
import tabArrow from "../../assets/landing_page/tab-arrow.svg";

function WayFoWork() {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  const setTab = (action) => {
    if (typeof action === "number") {
      setActiveTabIndex(action);
    } else if (action === "prev" && activeTabIndex > 0) {
      setActiveTabIndex((prevIndex) => prevIndex - 1);
    } else if (action === "next" && activeTabIndex < data.items.length - 1) {
      setActiveTabIndex((prevIndex) => prevIndex + 1);
    }
  };

  const [openRemote, setOpenRemote] = React.useState(false);
  const [openSecurity, setOpenSecurity] = React.useState(false);

  const handleOpenRemote = () => {
    setOpenRemote(true);
  };

  const handleCloseRemote = () => {
    setOpenRemote(false);
  };

  const handleOpenSecurity = () => {
    setOpenSecurity(true);
  };

  const handleCloseSecurity = () => {
    setOpenSecurity(false);
  };

  return (
    <div className={styles.WayFoWork}>
      <div className={styles.wrapper}>
        <div className={styles.block1}>
          <h1>{data.title}</h1>
        </div>
        <div className={styles.block2}>
          <p>{data.block2}</p>
        </div>
        <div className={styles.block3}>
          <div className={styles.tabMobileButtons}>
            <h6>{data.stepsWeTake}</h6>

            <div>
              <button
                onClick={() => setTab("prev")}
                className={styles.prev}
                disabled={activeTabIndex === 0}
              >
                <img src={arrow} alt="" />
              </button>
              <button
                onClick={() => setTab("next")}
                className={styles.next}
                disabled={activeTabIndex === data.items.length - 1}
              >
                <img src={arrow} alt="" />
              </button>
            </div>
          </div>
          <div className={styles.tabsContainer}>
            <div className={styles.tabs}>
              {data.items.map((tab, index) => (
                <div
                  key={index}
                  className={`${styles.tab} ${
                    activeTabIndex === index ? styles.active : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  0{index + 1}
                </div>
              ))}
            </div>
            <div className={styles.tabContent}>
              <div>
                <span>0{activeTabIndex + 1}</span>
                <h6>{data.items[activeTabIndex].title}</h6>
              </div>
              <p>{data.items[activeTabIndex].content}</p>
            </div>
          </div>
        </div>
        <div className={styles.block4}>
          <img src={block4Bg} alt="background" />
        </div>
        <div className={styles.block5}>
          <div className={styles.steps}>
            <div>
              <img src={arrow} alt="" />
            </div>
            <p>{data.stepsTitle}</p>
          </div>

          <div className={styles.products}>
            <h2>{data.howWeWork}</h2>
            <ul>
              {data.products.map((item, index) => (
                <NavLink to={item.route} key={index}>
                  <li>{item.title}</li>
                </NavLink>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.block6}>
          <div className={styles.content}>
            <h2>{data.remoteWorkTitle}</h2>
            <p>{data.remoteWorkContent}</p>
            <div className={styles.buttonWrap}>
              <Button onClick={handleOpenRemote}>
                <img src={tabArrow} alt="arrow" />
              </Button>
            </div>
          </div>

          <div className={styles.pattern}>/////////////////////////////</div>
        </div>
        <div className={styles.block7}>
          <div className={styles.steps}>
            <div>
              <img src={arrow} alt="" />
            </div>
            <p>{data.securityStepstitle}</p>
          </div>

          <div className={styles.content}>
            <h2>{data.securityTitle}</h2>
            <p>{data.securityContent}</p>
            <div className={styles.buttonWrap}>
              <Button onClick={handleOpenSecurity}>
                <img src={tabArrow} alt="arrow" />
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.block8}></div>
      </div>

      <Modal
        open={openRemote}
        onClose={handleCloseRemote}
        aria-labelledby="Remote-title"
        aria-describedby="Remote-description"
        className={globalStyles.modal}
      >
        <div className={globalStyles.modalContent}>
          <Button onClick={handleCloseRemote} sx={{ mt: 2 }}>
            x
          </Button>
          <ul>
            {data.remoteModalUl.map((item, index) => (
              <li key={index}>{item.li}</li>
            ))}
          </ul>
        </div>
      </Modal>

      <Modal
        open={openSecurity}
        onClose={handleCloseSecurity}
        aria-labelledby="Security-title"
        aria-describedby="Security-description"
        className={globalStyles.modal}
      >
        <div className={globalStyles.modalContent}>
          <Button onClick={handleCloseSecurity} sx={{ mt: 2 }}>
            x
          </Button>
          <p>{data.securityModalStart}</p>
          <ul>
            {data.securityModalUl.map((item, index) => (
              <li key={index}>{item.li}</li>
            ))}
          </ul>
          <p>{data.securityModalEnd}</p>
        </div>
      </Modal>
    </div>
  );
}

export default WayFoWork;
