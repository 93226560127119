import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ReCAPTCHA from "react-google-recaptcha";
import SelectCountry from "react-select-country-list";
import styles from "./vacancies.module.scss";

const ApplicationForm = ({ vacancyId, onClose }) => {
  const [formValues, setFormValues] = useState({
    vacancy_id: vacancyId,
    locale: "en",
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
    attachment: null,
    privacyPolicyChecked: false,
    "g-recaptcha-response": "",
  });

  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState({});
  const [wrong, setWrong] = useState(false);

  const handleChange = (event) => {
    const { name, value, files, checked, type } = event.target;
    if (type === "file") {
      setFormValues({ ...formValues, [name]: files[0] });
      setFileName(files[0]?.name);
    } else if (type === "checkbox") {
      setFormValues({ ...formValues, [name]: checked });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formValues.name.trim()) {
      newErrors.name = "Full Name is required";
      isValid = false;
    }

    if (!formValues.email.trim()) {
      newErrors.email = "E-mail is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "E-mail address is invalid";
      isValid = false;
    }

    if (!formValues.privacyPolicyChecked) {
      newErrors.privacyPolicyChecked = "Please accept the privacy policy";
      isValid = false;
    }

    if (!formValues.country) {
      newErrors.country = "Country is required";
      isValid = false;
    }

    if (!formValues.message.trim()) {
      newErrors.message = "Tell us why you would be a good addition to our team";
      isValid = false;
    }

    if (!formValues["g-recaptcha-response"]) {
      newErrors.recaptcha = "Please verify that you are not a robot";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const updatedFormValues = {
      ...formValues,
      country: formValues.country.toLowerCase(),
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(updatedFormValues)) {
      formData.append(key, value);
    }

    try {
      const response = await fetch("https://api.xybos.tech/api/vacancy-form", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'successfulVacanciesForm',
            page_location: window.location.href,
          });
        }
        onClose();
      } else {
        setWrong(true);
        console.error("Error submitting form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.formWrap}>
      <div className={styles.inputWrap}>
        <div className={styles.textFieldRow}>
          <TextField
            className={styles.textField}
            label="Full Name*"
            name="name"
            variant="standard"
            value={formValues.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name || ""}
          />
          <TextField
            className={styles.textField}
            label="E-mail*"
            name="email"
            variant="standard"
            value={formValues.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email || ""}
          />
        </div>
        <div className={styles.textFieldRow}>
          <TextField
            className={styles.textField}
            label="Phone"
            name="phone"
            variant="standard"
            value={formValues.phone}
            onChange={handleChange}
          />
          <FormControl variant="standard" className={styles.textField}>
            <InputLabel>Country*</InputLabel>
            <Select
              name="country"
              value={formValues.country}
              onChange={handleChange}
              error={!!errors.country}
            >
              {SelectCountry()
                .getData()
                .map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
            </Select>
            <div className={styles.errorText}>{errors.country || ""}</div>
          </FormControl>
        </div>
        <TextField
          className={styles.textArea}
          label="Tell us why you would be a good addition to our team *"
          name="message"
          variant="standard"
          value={formValues.message}
          onChange={handleChange}
          multiline
          rows={1}
          error={!!errors.message}
          helperText={errors.message || ""}
        />

        <FormControlLabel
          className={styles.privacyPolicy}
          control={
            <Checkbox
              name="privacyPolicyChecked"
              checked={formValues.privacyPolicyChecked}
              onChange={handleChange}
            />
          }
          label="I agree to the Privacy Policy"
        />
        {errors.privacyPolicyChecked && (
          <p className={styles.errorText}>{errors.privacyPolicyChecked}</p>
        )}

        {wrong && (
          <p className={styles.errorText}>Something went wrong, please try again</p>
        )}
      </div>

      <div className={styles.footer}>
        <div>
          <ReCAPTCHA
            sitekey="6LdgF5QoAAAAAJ8e1BR2HEhk8qS3MWp7AUVgIgab"
            onChange={(value) =>
              setFormValues({ ...formValues, "g-recaptcha-response": value })
            }
          />
        </div>
        <div className={styles.fileInputContainer}>
          <input
            accept=".pdf,.doc,.docx"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            name="attachment"
            onChange={handleChange}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="outlined"
              component="span"
              startIcon={<AttachFileIcon />}
            >
              <span>Attach resume</span>
              <span className={styles.fileSizeText}>
                {fileName ? fileName : "*Max file size: 50 MB"}
              </span>
            </Button>
          </label>
        </div>

        <div>
          <Button
            variant="contained"
            type="submit"
            className={styles.submitButton}
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ApplicationForm;
