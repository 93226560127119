import React from "react";
import styles from "./textBox.module.scss";

const TextBox = ({text}) => (
  <div className={styles.textWrap}>
    <h2 dangerouslySetInnerHTML={{ __html: text }}></h2>
  </div>
);

export default TextBox;
