import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./vacancies.module.scss";
import { Modal, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import ApplicationForm from "./ApplicationForm";

const VacanciesSingle = () => {
  let { slug } = useParams();
  const [vacancies, setVacancies] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    fetch(`https://api.xybos.tech/api/vacancies/${slug}`)
      .then((response) => response.json())
      .then((data) => setVacancies(data))
      .catch((error) => console.log(error));
  }, [slug]);


  // Loader while vacancies data is null
  if (vacancies === null) {
    return <div className={styles.loadingWrapper}><CircularProgress /></div>;
  }

  return (
    vacancies && (
      <div className={styles.vacancySingleWrap}>
        <div className={styles.vacancySingle}>
          <div className={styles.title}>
            <h1>{vacancies.title}</h1>
          </div>

          <div className={styles.contenIntro}>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: vacancies.intro }}
            ></div>
            <div className={styles.pattern}></div>
          </div>

          <div className={styles.contentOnetitle}>
            <div className={styles.patternStart}></div>
            <h2>We are looking for you if:</h2>
            <div className={styles.pattern}></div>
          </div>

          <div className={styles.contentOne}>
            <div className={styles.patternStart}>
              <div></div>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: vacancies.section_one }}
            ></div>
          </div>

          <div className={styles.quote}>
            <p>
              Regardless of the long list of expectations mentioned above you do
              not need to be an expert in all areas. Equally important as all
              tech skills are how you fit into the group as a personality
            </p>
          </div>

          <div className={styles.contenTwoTitle}>
            <div className={styles.patternStart}></div>
            <h2>By joining us you will get:</h2>
          </div>

          <div className={styles.contentTwo}>
            <div className={styles.patternStart}>
              <p>
                /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
              </p>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: vacancies.section_two }}
            ></div>
          </div>

          <div className={styles.contentTags}>
            {vacancies?.tags.map((item, index) => {
              return <p key={index}>{item.name}</p>;
            })}
          </div>

          <div className={styles.footer}>
            <div className={styles.firstBlock}></div>
            <div className={styles.secondBlock}>
              <p>Ready to be part of our team?</p>
            </div>
            <div className={styles.thirdBlock}>
              <button onClick={handleOpen}>Apply</button>
            </div>
          </div>
        </div>

        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={styles.modal}
        >
          <div className={styles.modalContent}>
            <Button
              className={styles.closeModal}
              onClick={handleClose}
              sx={{ mt: 2 }}
            >
              x
            </Button>
            <ApplicationForm
              vacancyId={vacancies?.id}
              onClose={() => setOpenModal(false)}
            />
          </div>
        </Modal>
      </div>
    )
  );
};

export default VacanciesSingle;
